.quiz-container{
    background: rgb(65, 93, 115);
    /* height: 100vh; */
    padding: 0% 3% 3% 3%;
}

.inner-quiz{
    background: white;
    height: 100%;
    /* padding-top: 3%; */
}

.quiz{
    max-width: 700px;
    height: 100%;
    padding: 0px 20px 0px 0px;
    margin:auto;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.evaluate{
    cursor: pointer;
    background-color: rgb(77, 216, 77);
    outline: none;
    border: none;
    padding: 20px;
    text-align: center;
    text-decoration: none;
    color: black;
    border-radius: 5px;
    /* box-shadow: 2px 2px 2px; */
}