.logo{
    text-align: center;
}

.logo img{
    /* display: block; */
    /* margin: auto; */
    width: 90%;
    max-width: 550px;
    /* height: auto; */
}

/* .buttons-lang button{ */
    /* width: 200px; */
/* } */