.question {
    user-select: none;
}
.question img {
    margin-left: 10px;
    margin-right: 10px;
    width: 50px;
    display: block;
    float: left;
}

.question .texto {
    float: left;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    box-sizing: border-box;
    background: #cce5ff;
    text-align: center;
    width: 200px;
    padding: 13px 0px;
    margin-right: 50px;
    /* box-shadow: 5px 5px 5px; */
    border-radius: 7px;
    cursor: pointer;
}

@media only screen and (max-width: 850px) {
    .question .texto {
        margin-right: 25px;
    }
}

/* Switch */

.switch {
    position: relative;
    display: inline-block;
    margin-top: 8px;
    width: 60px;
    height: 34px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgb(236, 48, 48);
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 34px;
}

.switch.disabled .slider {
    background-color: gray;
}

.slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: 0.4s;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: rgb(76, 160, 7);
}

input:focus + .slider {
    box-shadow: 0 0 1px rgb(76, 160, 7);
}

input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

/* Checkbox */

.checkmark-container {
    display: block;
    height: 50px;
    float: right;
    position: relative;
    box-sizing: border-box;
    padding-left: 35px;
    padding-top: 9px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.checkmark-container .big {
    display: none;
}

@media only screen and (min-width: 850px) {
    .checkmark-container .small {
        display: none;
    }
    .checkmark-container .big {
        display: block;
    }
}

/* Hide the browser's default checkbox */
.checkmark-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    display: block;
    float: left;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    height: 25px;
    width: 25px;
    background-color: #aaa;
}

/* On mouse-over, add a grey background color */
.checkmark-container:hover input ~ .checkmark {
    background-color: #888;
}

/* When the checkbox is checked, add a blue background */
.checkmark-container input:checked ~ .checkmark {
    background-color: #2196f3;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checkmark-container input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.checkmark-container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

@media only screen and (max-width: 550px) {
    .question {
        display: flex;
        align-items: center;
    }
    .question .texto {
        width: 150px;
        height: 120px;
    }
    .switch {
        display: block;
    }
    .checkmark-container {
        display: block;
        float: none;
    }
    .group {
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
}

.number-input{
    width: 30px;
    display: inline-block;
    margin-top: 8px;
    width: 60px;
}