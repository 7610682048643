.container-text {
    display: flex;
    flex-wrap: wrap;
}

.disclaimer-container .logo-container{
    /* float: left; */
    width: 45%;
}

.disclaimer-container .logo-container img{
    display: block;
    margin: auto;
    width: 90%;
    margin-top: 150px;    
}

.disclaimer-container .text{    
    /* float: left; */
    width: 55%;
    text-align: justify;
}

.disclaimer-container .text span{
    display: block;
    font-size: 18px;
}


.disclaimer-container .text b{
    display: block;
    text-align: center;
}

.disclaimer-container .text h1{
    text-align: center;
}

@media only screen and (max-width: 992px) {
    .disclaimer-container .logo-container{
        display: none;
    }
    
    .disclaimer-container .logo-container img{
        display: block;
        margin: auto;
        margin-top: 100px;    
    }
    
    .disclaimer-container .text{    
        /* float: left; */
        width: 100%;
    }
}

.privacy-policy h1{
    text-align: center;
}