.result-container{
    /* background: rgb(65, 93, 115); */
    box-sizing: border-box;
    border: 3vw solid rgb(65, 93, 115);
    border-top: none;
    min-height: calc(100vh);    
    /* padding: 0% 3% 3% 3%; */
}

.result-inner-container{
    background: white;
}

.results{
    height: 100%;
    margin: auto;
    max-width: 700px;
}

.panel{    
    display:flex;
    justify-content:center;
    align-items:center;
    /* height: 50px; */
    box-sizing: border-box;
    background: #fcce00;
    text-align: center;
    width: 200px;
    padding: 13px 0px; 
    /* box-shadow: 5px 5px 5px; */
    border-radius: 5px;
}

.panel.large{
    width: 300px;
}

.panel.green{
    background: #d4edda;
}

.panel.centralized{
    margin: auto;
}

.result-item{
    width: 100%;
    display: flex;
    justify-content: space-around;    
}

.result-value{
    box-sizing: border-box;
    border: 4px solid blue;
    width: 200px;
    font-size: 24px;
    display:flex;
    justify-content:center;
    align-items:center;
}

.result-value.red{
    border: 4px solid red;
}

.alert{
    /* background: rgba(255, 0, 0, 0.5); */
    color: black !important;
    width: 100%;
    text-align: center;    
}

.alert.warning{
    background: yellow;
}

.green-font{
    color: black !important;
}

@media only screen and (max-width: 650px) {
    .result-item .panel.large{
        width: 50%;
    }
    .result-value{
        width: 35%;
    }   
}

img.table{
    width: 100%;
}